import { combineReducers } from '@reduxjs/toolkit';
import permissionsGroupsReducer from './slices/groupPermissions';
import usersReducer from './slices/users';
import kpisReducer from './slices/kpis';
import permissionReducer from './slices/permissions';
import workTimeReducer from './slices/workTimes';
import statClientResReducer from './slices/statClientResponse';
import callsReducer from './slices/calls';
import statsClientReducer from './slices/statsClient';
import analyticsReducer from './slices/analytics';
import todoReducer from './slices/todos';
import notificationReducer from './slices/notifications';
import officesReducer from './slices/offices';
import notesReducer from './slices/notes';
import codesReducer from './slices/codes';
import expensesReducer from './slices/expenses';
import cashRegisterReducer from './slices/cash-register';
import commonSliceReducer from './slices/commonSlice';
import foldersReducer from './slices/folder';
import SessionsReducer from './slices/session';
import agentPerformance from './slices/agentPerformance';
import bonusAmounts from './slices/bonusAmount';
import receiptPayment from './slices/receiptPayment';
import paymentNotes from './slices/paymentNotes';
// ----------------------------------------------------------------------
var combinedReducer = combineReducers({
    permissions: permissionReducer,
    permissions_groups: permissionsGroupsReducer,
    users: usersReducer,
    workTimes: workTimeReducer,
    kpis: kpisReducer,
    calls: callsReducer,
    statsClient: statsClientReducer,
    statClientResponses: statClientResReducer,
    todos: todoReducer,
    analytics: analyticsReducer,
    notifications: notificationReducer,
    offices: officesReducer,
    notes: notesReducer,
    codes: codesReducer,
    expenses: expensesReducer,
    cashRegister: cashRegisterReducer,
    common: commonSliceReducer,
    folders: foldersReducer,
    sessions: SessionsReducer,
    agentPerformance: agentPerformance,
    bonusAmounts: bonusAmounts,
    receiptPayment: receiptPayment,
    paymentNotes: paymentNotes,
});
var rootReducer = function (state, action) {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }
    return combinedReducer(state, action);
};
export default rootReducer;
